







































































































































































































































































































































































































































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import QRCode from 'qrcode'
import Cookies from "js-cookie";
import axios, { AxiosResponse } from "axios";
import Swal from "sweetalert2";
import MD5 from "crypto-js/md5";
import moment from 'moment';
import { getUserProfile } from "@/api/profile";


type Device = {
  serial: string
  expires: string
  name: string | null
}

type License = {
  id: string | number
  priceYear: number
  tsds: number
  workSpaces: number
  name: string
  type: string
}

async function pause(ms: number): Promise<void> {
  return new Promise(resolve => {
    setTimeout(resolve, ms)
  })
}

@Component
export default class CabinetView extends Vue {
  showChangeLicenseDialog = false

  get licensePriceDifference(): number {
    const timeDifference = moment(this.profile.licenseExpires, 'DD.MM.YYYY').diff(moment(), 'months')
    let price: number
    if (this.selectedLicense === 'custom') {
      price = this.additionalTSD * 1300 + this.additionalWorkSpaces * 2200
    } else {
      price = this.findLicense(this.selectedLicense).priceYear
    }
    const totalPrice = (price - this.profile.price) / 12 * timeDifference
    return totalPrice < 0 ? 0 : totalPrice
  }
  changeLoading = false
  async changeLicense(): Promise<void> {
    this.changeLoading = true
    await axios.post('/changeLicense/', {
      licenseId: this.selectedLicense,
      tsds: this.additionalTSD,
      workspaces: this.additionalWorkSpaces,
      price: this.totalPrice
    })
    await this.fetchProfile()
    this.showChangeLicenseDialog = false
    this.changeLoading = true
  }

  showMenu = false
  checkTSDListInterval = -1

  @Watch('showMenu')
  onShowMenuChanges(value: boolean): void {
    if (value) {
      this.checkTSDListInterval = window.setInterval(this.checkTSDListOnNew, 2000)
    } else {
      window.clearInterval(this.checkTSDListInterval)
    }
  }

  newTSDName = ''
  newTSDStep = 1

  nextNewTSDStep(): void {
    this.newTSDStep = 2
    this.$nextTick(this.renderQRCode)
  }

  renderQRCode(): void {
    let canvas: HTMLElement | null = document.getElementById('qr')
    if (canvas === null) {
      canvas = document.getElementById('qr_1c')
    }
    const token = `${Cookies.get('token')};${this.newTSDName}`
    if (token) {
      QRCode.toCanvas(canvas, token, {width: 300}, ((error: any) => {
        if (error) console.error(error)
      }))
    }
  }

  closeDialog(): void {
    this.showMenu = false
    this.showHowConnect1C = false
    this.newTSDStep = 1
    this.getTSDList()
    this.getWorkSpaceList()
  }

  tsdList: Device[] = []
  loadingTSDList = false

  async getTSDList(): Promise<void> {
    this.loadingTSDList = true
    try {
      const {data} = await axios.get('/availableDevices/', {
        params: {
          token: Cookies.get('token')
        }
      })
      // await pause(2000)
      this.loadingTSDList = false
      this.tsdList = data
      this.showRemoveTSDDialog = this.tsdList.length > this.profile.availableTSD
    } catch (e) {
      console.error(e)
    }
  }

  workSpaceList: Device[] = []
  loadingWorkSpace = false

  async getWorkSpaceList(): Promise<void> {
    this.loadingWorkSpace = true
    const {data} = await axios.get('availableDevices/', {
      params: {
        token: Cookies.get('token'),
        type: '1C'
      }
    })
    this.loadingWorkSpace = false
    this.workSpaceList = data
    this.showRemoveWorkSpaceDialog = this.workSpaceList.length > this.profile.availableWorkSpace
  }

  async checkTSDListOnNew(): Promise<void> {
    try {
      const {data} = await axios.get('/availableDevices/', {
        params: {
          token: Cookies.get('token')
        }
      })
      if (data === 'no client') {
        await this.$router.push({name: 'login'})
      } else {
        if (this.tsdList.length !== data.length) {
          this.closeDialog()
          await Swal.fire({
            icon: 'success',
            title: 'ТСД успешно привязан',
            toast: true,
            position: 'top-end',
            timer: 4000,
            showConfirmButton: false
          })
        }
      }
    } catch (e) {
      window.clearInterval(this.checkTSDListInterval)
      return
    }
  }

  showChooseLicenseDialog = false
  selectedLicense: number | string = 1

  @Watch('selectedLicense')
  onSelectedLicenseChange(newValue: string | number): void {
    const license = this.findLicense(newValue)
    if (license.type === 'custom') return
    this.additionalTSD = license.tsds
    this.additionalWorkSpaces = license.workSpaces
  }

  additionalTSD = 0
  additionalWorkSpaces = 0
  licenses: License[] = []
  customLicense: License = {
    id: 'custom',
    name: 'своя',
    type: 'custom',
    tsds: 1,
    workSpaces: 1,
    priceYear: 0,
  }

  findLicense(id: string | number): License {
    const license = this.licenses.find((el) => el.id === id)
    if (license) {
      return license
    }
    return this.customLicense
  }

  get totalPrice(): number {
    const license = this.licenses.find(el => el.id === this.selectedLicense)
    let price: number
    if (license) {
      price = license.priceYear
    } else {
      price = this.additionalTSD * 1300 + 2200 * this.additionalWorkSpaces
    }
    return price
  }

  priceToShow = 0

  @Watch('totalPrice')
  async onTotalPriceChanged(newValue: number, oldValue: number): Promise<void> {
    let step = Math.abs(newValue - oldValue) / 10
    if (newValue < oldValue) {
      step *= -1
    }
    // eslint-disable-next-line no-constant-condition
    while (true) {
      this.priceToShow += step
      if (
          (newValue > oldValue && this.priceToShow >= newValue) ||
          (newValue < oldValue && this.priceToShow <= newValue)
      ) {
        this.priceToShow = newValue
        break
      }
      await pause(1)
    }
    this.priceToShow = newValue

  }

  profile = {
    name: '',
    availableTSD: 0,
    availableWorkSpace: 0,
    licenseExpires: '',
    licenseStatus: 'no',
    price: 0,
    id: 0,
    licenseEndSoon: false,
    licenseType: 'custom'
  }
  loading = false

  async fetchProfile(): Promise<void> {
    this.loading = true
    this.profile = await getUserProfile()
    this.loading = false
  }

  async refreshAll(): Promise<void> {
    await this.fetchProfile()
    await Promise.all([this.getWorkSpaceList(), this.getTSDList()])
  }

  get getButtonPay(): string {
    const btn = document.getElementById('pay-btn')
    const price = this.totalPrice

    if (btn) {
      const params = 'MerchantLogin=sm-kassa&' +
          `OutSum=${this.showChangeLicenseDialog ? this.licensePriceDifference : price}&` +
          'InvId=0&' +
          'Description=Лицензия&' +
          `SignatureValue=${this.getSignatureValue(this.showChangeLicenseDialog ? this.licensePriceDifference : price, this.profile.id)}&` +
          'IsTest=1&' +
          `shp_client_id=${this.profile.id}&` +
          `shp_license_id=${this.selectedLicense}&` +
          `shp_tsd=${this.additionalTSD}&` +
          `shp_workspaces=${this.additionalWorkSpaces}`

      btn.innerHTML = `
        <iframe
            width="300"
            height="70"
            style="border:0;width:177px;height:75px;overflow:hidden;background-color:transparent;"
            allowTransparency="true"
            src="https://auth.robokassa.ru/Merchant/PaymentForm/FormV.if?${params}"></iframe>`
    }
    let html = '<script type="text/javascript" src="https://auth.robokassa.ru/Merchant/bundle/robokassa_iframe.js" style=""'
    html += '></'
    html += ' script>'
    return html
  }

  getSignatureValue(sum: number, client_id: number): string {
    const str = 'sm-kassa:' +
        `${sum}:` +
        '0:' +
        `${process.env.VUE_APP_test_robokassa_pass_1}:` +
        `shp_client_id=${client_id}:` +
        `shp_license_id=${this.selectedLicense}:` +
        `shp_tsd=${this.additionalTSD}:` +
        `shp_workspaces=${this.additionalWorkSpaces}`
    return MD5(str).toString().toLocaleUpperCase()
  }

  async getAvailableLicenses(): Promise<void> {
    const {data} = await axios.get('availableLicenses/')
    this.licenses = data.map((el: any) => ({
      id: el.id,
      name: el.name,
      priceYear: el.price,
      workSpaces: el['1c_amount'],
      tsds: el['tsd_amount'],
      type: 'regular'
    }))
  }

  showHowConnect1C = false
  indentificator1CNumbersCount = 9
  indentificator1C = ""

  async registerWorkSpace(): Promise<void> {
    if (this.indentificator1C.length <= 8) return
    const {data} = await axios.post('registerWorkSpace/', {
      uniqueSerial: this.indentificator1C,
    })
    if (data.status === 'ok') {
      this.close1CRegistration()
      await this.getWorkSpaceList()
    }
  }

  close1CRegistration(): void {
    this.showHowConnect1C = false
    this.indentificator1C = ''
  }

  async checkWorkSpacesList(): Promise<void> {
    const {data} = await axios.get('/availableDevices/', {
      params: {
        token: Cookies.get('token'),
        type: '1C'
      }
    })
    if (data === 'no client') {
      await this.$router.push({name: 'login'})
    } else {
      if (this.workSpaceList.length !== data.length) {
        this.closeDialog()
        await Swal.fire({
          icon: 'success',
          title: 'Рабочее место успешно привязано',
          toast: true,
          position: 'top-end',
          timer: 4000,
          showConfirmButton: false
        })
      }
    }
  }

  printQRCode1C(): void {
    const printWindow = window.open('', 'PRINT', 'height=400,width=600');
    if (printWindow === null) return

    printWindow.document.write('<html lang="ru"><head><title>' + document.title + '</title>');
    printWindow.document.write('</head><body >');
    const printArea = document.getElementById('print')
    if (printArea) {
      printWindow.document.write(printArea.innerHTML);
      printWindow.document.write('</body></html>');

      const canvas = printWindow.document.getElementById('qr_1c')
      if (canvas) {
        const token = `${Cookies.get('token')};${this.newTSDName}`
        QRCode.toCanvas(canvas, token, {width: 300}, ((error: any) => {
          if (error) console.error(error)
        }))
      }

      printWindow.document.close();
      printWindow.focus();

      printWindow.print();
    }
    printWindow.close();

  }

  openLicenseDialog(): void {
    if (this.profile.licenseType === 'custom') {
      this.selectedLicense = this.profile.licenseType
      this.additionalWorkSpaces = this.profile.availableWorkSpace
      this.additionalTSD = this.profile.availableTSD
    } else {
      this.selectedLicense = +this.profile.licenseType
    }
    this.showChooseLicenseDialog = true
  }

  confirmDeleteTSDDialog = false
  confirmDeleteWorkSpaceDialog = false
  loadingDelete = false
  tsdToDelete = ''

  async deleteTSDFromProfile(type: string): Promise<void> {
    this.loadingDelete = true
    await axios.delete('/deleteTSD/', {
      params: {
        serial: this.tsdToDelete
      }
    })
    if (type === 'TSD') {
      await this.getTSDList()
    } else {
      await this.getWorkSpaceList()
    }
    this.loadingDelete = false
    this.confirmDeleteTSDDialog = false
    this.confirmDeleteWorkSpaceDialog = false
  }

  openConfirmDeleteTSD(serial: string, type = 'TSD'): void {
    this.tsdToDelete = serial
    this.confirmDeleteTSDDialog = type === 'TSD'
    this.confirmDeleteWorkSpaceDialog = type === '1C'
  }

  showRemoveTSDDialog = false
  showRemoveWorkSpaceDialog = false
  tsdListToDelete: string[] = []
  workSpaceListToDelete: string[] = []

  async deleteDevicesFromProfile(type = 'TSD'): Promise<void> {
    this.loadingDelete = true
    const promises: Promise<AxiosResponse>[] = []
    console.log(type)
    for (const serial of type === 'TSD' ? this.tsdListToDelete : this.workSpaceListToDelete) {
      const promise = axios.delete('/deleteTSD/', {
        params: {
          serial: serial
        }
      })
      promises.push(promise)
    }
    await Promise.all(promises)
    if (type === 'TSD') {
      await this.getTSDList()
    } else {
      await this.getWorkSpaceList()
    }
    this.loadingDelete = false
  }


  created(): void {
    if (this.$route.hash === '#success') {
      Swal.fire({
        icon: 'success',
        title: 'Оплата прошла успешно, скоро Ваша лицензия станет активной',
        toast: true,
        position: 'top-end',
        timer: 6000,
        showConfirmButton: false
      })
      this.$router.push({name: 'cabinet', hash: ''})
    } else if (this.$route.hash === '#fail') {
      Swal.fire({
        icon: 'error',
        title: 'Оплата не прошла',
        toast: true,
        position: 'top-end',
        timer: 6000,
        showConfirmButton: false
      })
      this.$router.push({name: 'cabinet', hash: ''})
    }

    this.priceToShow = this.totalPrice

    const frame = document.getElementById('robokassa_iframe')
    if (frame) {
      frame.setAttribute('allowpaymentrequest', '')
    }

    this.refreshAll()
    this.getAvailableLicenses()
  }
}
