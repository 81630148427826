
















































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Validations } from 'vuelidate-property-decorators';
import { required, email, minLength } from "vuelidate/lib/validators";
import axios from "axios";
import Cookies from "js-cookie";

const touchMap = new WeakMap()

@Component
export default class RegistrationView extends Vue {
  name: string | null = ''

  get nameErrors(): string[] {
    const errors: string[] = []
    if (!this.$v.name.$dirty) return errors
    !this.$v.name.required && errors.push('Обязательное поле')
    return errors
  }

  email: string | null = ''
  emailLoading = false

  async checkEmailOnExist(): Promise<boolean> {
    if (!this.$v.email.email || !this.$v.email.$dirty) return false
    this.emailLoading = true
    const {data} = await axios.post('checkEmail/', {
        email: this.email
    })
    this.emailLoading = false
    return data === 'ok'
  }

  get emailErrors(): string[] {
    const errors: string[] = []
    if (!this.$v.email.$dirty) return errors
    !this.$v.email.required && errors.push('Обязательное поле')
    !this.$v.email.email && errors.push('Некорректная почта')
    !this.$v.email.exists && errors.push('Данная почта уже используется')
    return errors
  }

  pass: string | null = ''

  get progress (): number {
    if (this.pass)
      return Math.min(100, this.pass.length * 8)
    else
      return 0
  }
  get color (): string {
    return ['error', 'warning', 'success'][Math.floor(this.progress / 40)]
  }

  get passErrors(): string[] {
    const errors: string[] = []
    if (!this.$v.pass.$dirty) return errors
    !this.$v.pass.required && errors.push('Обязательное поле')
    !this.$v.pass.minLength && errors.push('Минимальная длинна пароля 8')
    return errors
  }

  passCopy: string | null = ''

  passIsIdentity(value: string | null): boolean {
    return value === this.pass
  }

  get passCopyErrors(): string[] {
    const errors: string[] = []
    if (!this.$v.passCopy.$dirty) return errors
    !this.$v.passCopy.required && errors.push('Обязательное поле')
    !this.$v.passCopy.passIsIdentity && errors.push('Пароли не совпадают')
    return errors
  }

  delayTouch ($v: {$reset: () => void, $touch: () => void}): void {
    $v.$reset()
    if (touchMap.has($v)) {
      clearTimeout(touchMap.get($v))
    }
    const touch = setTimeout(() => {
      $v.$touch()
    }, 200)
    touchMap.set($v, touch)
  }

  @Validations()
  validations = {
    name: {required},
    email: {required, email, 'exists': this.checkEmailOnExist},
    pass: {required, minLength: minLength(8)},
    passCopy: {
      required,
      'passIsIdentity': this.passIsIdentity
    }
  }

  showPass = false
  showPassCopy = false
  loading = false

  async register(): Promise<void> {
    this.$v.$touch()
    if (this.$v.$invalid) return
    this.loading = true
    const {data} = await axios.post('registrationClient/', {
      name: this.name,
      email: this.email,
      pass: this.pass
    })
    Cookies.set('token', data)
    await this.$router.push({name: 'cabinet'})
    this.loading = false
  }


}
